export const ClientApis = {
  GET_DELAYS: 'api/v1/cblclient/get-project-timeline-delay',
  GANTT_CHART: 'api/v1/cblclient/gantt-chart',
  get_managers: 'api/v1/cblclient/all-managers-list',
  projects: 'api/v1/cblclient/projects',
  milestones: 'api/v1/cblclient/get-project-milestones-detail',
  projectFinance: 'api/v1/cblclient/project-finance-details',
  projectDocs: 'api/v1/cblclient/get-project-docs',
  projectEscalation: 'api/v1/cblclient/get-escalation',
  addEscalation: 'api/v1/cblclient/add-escalation',
  GET_PROJECT_BY_ID: 'api/v1/cblclient/get-project-by-id',
  getTweets: 'api/v1/cblclient/get-tweet',
  addTweet: 'api/v1/cblclient/add-tweet',

  getMessage: 'api/v1/cblclient/get-message',
  addMessage: 'api/v1/cblclient/add-message',

  viewMessage: 'api/v1/cblclient/view-message',

  conversationListing: 'api/v1/cblclient/conversation/home',
  getMessageConversation: 'api/v1/cblclient/conversation/getMessage',
  sendMessage: 'api/v1/cblclient/conversation/sendMessage',

  reportProjectStat: 'api/v1/cblclient/report-project-stat',
  getFiles: 'api/v1/cblclient/get-files',

  FEEDBACK: 'api/v1/cblclient/feedback',

  GET_ESCL_COMMENT: 'api/v1/cblclient/get-escl-comment',
  ADD_ESCL_COMMENT: 'api/v1/cblclient/add-escl-comment',
  GET_SUB_CLIENT: 'api/v1/cblclient/get-sub-client',
  ADD_SUB_CLIENT: 'api/v1/cblclient/add-sub-client',
  GET_CLIENT_PASSWORD: 'api/v1/cblclient/get-client-password',
  UPDATE_ESCL_STATUS: 'api/v1/cblclient/update-escl-status',

  //ZOOM_CREATE_MEETING: 'api/v1/manager/zoom/createMeeting',
  ZOOM_MEETINGS: 'api/v1/cblclient/zoom/meetings',
  ZOOM_VIEW_USERS: 'api/v1/cblclient/zoom/viewUsers',
  ZOOM_RECORDINGS: 'api/v1/cblclient/zoom/recordings',
  ZOOM_PARTICIPANTS: 'api/v1/cblclient/zoom/participants',
  ZOOM_SEND_INVITE_MEET: 'api/v1/cblclient/zoom/sendInvitesMeet',
  ESCL_NOTIFICATIONS: 'api/v1/cblclient/escl-notifications',

  SAVE_FCM_TOKEN: 'api/v1/cblclient/save-fcm-token',
  DELETE_SUB_CLIENT: 'api/v1/cblclient/delete-sub-client',
  UPDATE_PROFILE: 'api/v1/cblclient/update-profile',
  // GET_MAILS: 'api/v1/cblclient/get-mails',
  // GET_MAIL_FOLDERS: 'api/v1/cblclient/get-mail-folders',
  APPROVE_TIMELINE_CHANGE : 'api/v1/cblclient/approve-timeline-change',
  REJECT_TIMELINE_CHANGE  : 'api/v1/cblclient/reject-timeline-change',
  ESCL_LEVEL_UP : 'api/v1/cblclient/escl-level-up',
  ESCL_RESOLVE_REOPEN : 'api/v1/cblclient/escl-resolve-reopen'
};
