export const GlobalApis = {
  GET_EMP_BY_PROJECTID:'api/v1/global/get-project-team',
  GET_COUNTRIES: 'api/v1/global/get-countries',
  UPLOAD_ATTACHMENT: 'api/v1/global/upload-attachment',
  GET_DEPARTMENT: 'api/v1/global/get-department',
  GET_TECH_STACKS: 'api/v1/global/get-tech-stacks',
  GET_TEAMS: 'api/v1/global/get-teams',
  GET_DESIGINATION: 'api/v1/global/get-designation',
  GET_TIMEZONES: 'api/v1/global/get-timezones',
  GET_PROJECT_TYPES: 'api/v1/global/get-project-types',
  GET_TAGS: 'api/v1/global/get-tag',
  GET_PROJECT_DOC_LABELS: 'api/v1/global/get-project-doc_labels',
  GET_ALL_REGIONS: 'api/v1/global/get-all-regions',
  PROJECT_HOLD_REASONS: 'api/v1/global/get-on-hold-reasons',
  FORGOT_PASSWORD: 'api/v1/global/forgot-password',
  LOGIN: 'api/v1/global/login',
  GET_EMP_CATEGORIES: 'api/v1/global/get-emp-categories',
  GET_DISPLAY_DESIGNATION: 'api/v1/global/get-display-designations',
  GET_PROJECT_CATEGORIES: 'api/v1/global/get-project-categories',
  GET_PROJECT_SUB_CATEGORIES: 'api/v1/global/get-project-sub-categories',
  GET_PROJECT_TAGS: 'api/v1/global/get-project-tags',
  GET_PROJECT_LOG_TYPES: 'api/v1/global/projectLogType',
  GET_PROJECT_TASK_SAMPLE_EXCEL: 'api/v1/global/exportTaskImportTemplate',
  IMPORT_PROJECT_TASK_FROM_EXCEL: 'api/v1/global/importTasks',
  GET_BIT_UNLINKED_ACCOUNT: 'api/v1/global/get-unlinked-bit-accounts',
  LINK_BIT_ACCOUNT: 'api/v1/global/link-bit-acccount',
  GET_SALES_ROLES: 'api/v1/global/get-sale-roles',
  GET_UPSELL_STATUSES: 'api/v1/global/get-upsell-statuses',
  GET_CONTACT_MEDIUMS: 'api/v1/global/get-contact-mediums',
  GET_UPSELL_TYPES: 'api/v1/global/get-upsell-types',
  PROJECT_FEEDBACK: 'api/v1/global/project-feedback',
  GET_ESCL_STATUS: 'api/v1/global/get-escl-status',
  GET_CLIENT_TYPES: 'api/v1/global/client-types',
  GET_EXPENSE_TYPE: 'api/v1/global/expenseType',
  GET_EXPENSE_STATUS: 'api/v1/global/expenseStatus',
  GET_ALL_CSMLIST:'api/v1/global/all-csm-list',
  PLATFORM_LIST:'api/v1/global/escl-platforms',
  REPORT_LIST:'api/v1/global/escl-reported-tos'
};
