import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
// import { HttpParams } from '@angular/common/http';
// import { APP } from '../../core/globals';
import { GlobalApis } from 'src/app/core/globalApis';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('closeForgotPassword', { static: false })
  closeForgotPassword: ElementRef;
  public form: FormGroup;
  showError = false;
  public loading = false;
  public forgotForm: FormGroup;
  currentYear: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private admin: AdminService
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      is_portal_user: [false]
    });
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    const data = localStorage.getItem('api_token');
    if (data) {
      this.router.navigate(['/dashboard']);
    }
  }

  onSubmit(value) {
    if (this.forgotForm.valid) {
      this.admin.postData(GlobalApis.FORGOT_PASSWORD, value).subscribe((res: any) => {
        this.closeForgotPassword.nativeElement.click();
      });
    } else {
      this.showError = true;
    }
  }

  loginFun(value) {
    if (this.form.valid) {
      this.loading = true;
      value.is_portal_user = 0; // Manager/dev/qa/hr/others

      this.admin.postData(GlobalApis.LOGIN, value).subscribe(
        (res: any) => {
          if (res.success == 1 && res.data.developer_rate) {
            localStorage.setItem('developer_rate', res.data.developer_rate);
          }
          delete res.data.password;
          localStorage.setItem('user_data', JSON.stringify(res.data));

          if (res.data && (res.data.user_type || res.data.user_type == 0)) {
            localStorage.setItem('api_token', res.data.api_token);
            if (res.data && res.data.username) {
              localStorage.setItem('name', res.data.username);
            }
            localStorage.setItem('role_type', res.data.role_type);
            localStorage.setItem('user_type', res.data.user_type);
            this.router.navigate(['/admin/dashboard']);
          } else if (
            res.data &&
            res.data.designation &&
            res.data.designation.name
          ) {
            localStorage.setItem('api_token', res.data.api_token);
            if (res.data && res.data.name) {
              localStorage.setItem('name', res.data.name);
            }
            localStorage.setItem('desigination', res.data.designation.name);

            // Navigate to the dashboard according to the user.
            // Navigate to the dashboard according to the user.
            if (res.data.designation.name === 'Manager') {
              localStorage.setItem('is_senior', res.data.is_senior);
              localStorage.setItem('sr_manager_id', res.data.manager.id);
              localStorage.setItem('sr_manager_name', res.data.manager.name);
              localStorage.setItem('id', res.data.id);
              this.router.navigate(['/daily-log']);
            } else if (res.data.designation.name === 'Developer') {
              this.router.navigate(['/dev/dashboardev']);
            } else if (res.data.designation.name === 'Tester') {
              this.router.navigate(['/qa/dashboard']);
            } else if (res.data.designation.name === 'HR') {
              // this.router.navigate(['/hr/employees']);
              if ((res.data || {}).hr_hide_emp_list == 1) {
                localStorage.setItem('hr_hide_emp_list', '1');
              } else {
                localStorage.setItem('hr_hide_emp_list', '0');
              }
              this.router.navigate(['/hr/dashboard']);
            } else if (res.data.designation.name === 'Finance') {
              this.router.navigate(['/finance/attendance']);
            } else if (res.data.designation.name === 'Sales') {
              localStorage.setItem('is_senior', res.data.is_senior);
              localStorage.setItem('sale_role_id', res.data.sale_role_id);
              localStorage.setItem('sale_role_name', res.data.sale_role.name);
              this.router.navigate(['/sales/client']);
            } else if (res.data.designation.name === 'Marketing') {
              //localStorage.setItem('is_senior', res.data.is_senior);
              this.router.navigate(['/marketing/dashboard']);
            } else if (res.data.designation.name === 'CSM') {
              this.router.navigate(['/csm/dashboard']);
            }
          } else if (res.data && res.data.designation == null) {
            this.admin.errorAlert(
              'Please enter the designation from admin',
              false
            );
          }
          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
    } else {
      this.showError = true;
    }
  }

  ngOnDestroy() {
    this.closeForgotPassword.nativeElement.click();
  }
}
